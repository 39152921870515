import React from 'react'
import BlogMainImage from "../../assets/Blog Banner_1_11zon.webp"
const BlogsBanner = () => {
  return (
    <div>
        <img src={BlogMainImage} />
    </div>
  )
}

export default BlogsBanner
import React from 'react'
import BlogImage from "../../assets/case1.png"
const BlogList = () => {
  return (
    <div className='py-12 px-48 grid grid-cols-4 gap-4 grid-flow-row' >
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
        <div className='relative'>
            <img className='w-[300px] h-[300px]' src={BlogImage} />
            <div className='absolute bottom-3 px-3'>
            <p className='text-white font-[PoppinsMedium] text-[1rem]'>Gaming Microsite </p>
            <p className='text-white font-[PoppinsMedium] text-[1.1rem]'>Digital Arcade Game</p>
            </div>
        </div>
    </div>
  )
}

export default BlogList
import React from 'react'
import ContactMainImage from "../../assets/Contact Us copy_5_11zon.webp"
const ContactBanner = () => {
  return (
    <div>
        <img src={ContactMainImage} />
    </div>
  )
}

export default ContactBanner
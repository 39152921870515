import React from 'react'
import "./About.css"
import AboutMainImage from "../../assets/About Us.png"
const AboutBanner = () => {
  return (
    <div className=''>
   <img src={AboutMainImage}/>
</div>
  )
}

export default AboutBanner